/**
 * @module config
 * @description Service for the application configuration
 * @copyright Aura Health, Inc.
 */

// Internal dependencies
import packageConfig from '../package.json';

const { name, version } = packageConfig;

// Store the configuration
const config = {
  api: {
    auraServices: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/api/auraServices`,
    businessWeb: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/api`,
  },
  appDomain: process.env.NEXT_PUBLIC_APP_DOMAIN,
  branch: {
    branchKey: process.env.NEXT_PUBLIC_BRANCH_KEY,
  },
  bugsnag: {
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || '',
    appVersion: version,
    releaseStage: process.env.NEXT_PUBLIC_MODE,
  },
  environment: process.env.NODE_ENV,
  facebook: {
    appId: process.env.NEXT_PUBLIC_FACEBOOK_OAUTH_APP_ID || '',
  },
  google: {
    authClientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID || '',
  },
  logger: {
    level: process.env.NEXT_PUBLIC_LOGGER_LEVEL || 'info',
    useJSON: !(process.env.NEXT_PUBLIC_LOGGER_USE_JSON === 'false'),
  },
  mixpanel: {
    token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '',
  },
  mode: process.env.NEXT_PUBLIC_MODE,
  service: {
    name,
    version,
  },
  stripe: {
    stripeKey: process.env.NEXT_PUBLIC_STRIPE_KEY || '',
  },
};

export default config;
