import appConstants from '@/utils/constants/app';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AttributionData {
  feature: string;
  sourcePlatform: string;
}

interface ProfileState {
  attributionData: AttributionData;
}

export interface RootState {
  error: string | null;
  profile: ProfileState;
}

const initialState: RootState = {
  error: null,
  profile: {
    attributionData: {
      feature: `onboarding_web`,
      sourcePlatform: appConstants.APP_NAME,
    },
  },
};

export const onboardSlice = createSlice({
  initialState,
  name: 'onboard',
  reducers: {
    updateProfile: (state, action: PayloadAction<ProfileState>) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
});

export const { updateProfile } = onboardSlice.actions;

export default onboardSlice.reducer;
