import config from '@/config';
import onboard from './slices/onboard';
import thunkMiddleware from 'redux-thunk';
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

type RootState = ReturnType<typeof combinedReducers>;

const combinedReducers = combineReducers({
  onboard,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: RootState | undefined, action: any) => {
  let nextState;
  switch (action.type) {
    case HYDRATE:
      nextState = {
        ...state,
        ...action.payload,
      };
      return nextState;
    default:
      return combinedReducers(state, action);
  }
};

const initStore = () => {
  return configureStore({
    devTools: config.environment === 'development',
    middleware: [thunkMiddleware],
    reducer,
  });
};

export type AppStore = ReturnType<typeof initStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = ReturnType<typeof initStore>['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const reduxWrapper = createWrapper(initStore);
