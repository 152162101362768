import Bugsnag from '@bugsnag/js';
import config from '@/config';
import React from 'react';
import BugsnagReactPlugin, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';

const BugsnagClient = Bugsnag.start({
  apiKey: config.bugsnag.apiKey,
  appVersion: config.bugsnag.appVersion,
  plugins: [new BugsnagReactPlugin()],
  releaseStage: config.bugsnag.releaseStage,
});

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
export const ErrorBoundary = plugin.createErrorBoundary(React);

export default BugsnagClient;
