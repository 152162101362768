import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import config from '@Config';
import { ErrorBoundary } from '@/services/Bugsnag';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { reduxWrapper } from '@/store';
import { ToastProvider } from 'react-toast-notifications';
import { useState } from 'react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

function App({ Component, ...rest }: AppProps) {
  const { props, store } = reduxWrapper.useWrappedStore(rest);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: Infinity,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: Infinity,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Hydrate state={props.pageProps.dehydratedState}>
          <ToastProvider>
            <ErrorBoundary>
              <Component {...props.pageProps} />
            </ErrorBoundary>
          </ToastProvider>
          {config.environment === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </Hydrate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
